<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="18">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">{{ $t('Пришедшие заказы') }}</div>
              <div class="block-sarche">
                <div class="header__search ml-4">
                    <crm-input
                        :size="'small'"
                        :className="'w100'"
                        :class="mode ? 'input__day' : 'input__night'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="6" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <el-dropdown class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->



        <div class="card-table-header table-crm-smart custom__scroll">
            
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
            <thead>
                <tr>
                    <th class="w30p">

                    </th>
                    <th class="w30p" >
                      №
                    </th>
                    <th>
                      Клиент – ФИО клиента
                    </th>

                    <th>
                      Товары
                    </th>

                    <th>
                      Дата прихода
                    </th>

                    <th>
                      Рейс
                    </th>
                    <th>
                      Страна отправки
                    </th>
                    <th>
                      Страна прибытия
                    </th>
                    <th>
                      Филиал
                    </th>

                    <th>
                      Создан в
                    </th>
                    <th>
                      Ответственное лицо
                    </th>

                    <th class="w30p">
                      Настройки
                    </th>
                </tr>

                <tr class="filter_sorche">
                    <th>

                    </th>
                    <th v-if="columns.id.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.id"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.id.title"
                        class="id_input"
                        ></el-input>
                    </th>
                    

                    <th v-if="columns.typePayment.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.typePayment"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'ФИО'"
                        ></el-input>
                    </th>
                    <th v-if="columns.typePayment.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.typePayment"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'Товары'"
                        ></el-input>
                    </th>
                    <th v-if="columns.created_at.show">
                        <el-date-picker
                        :placeholder="columns.created_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.created_at"
                        size="mini"
                        >
                        </el-date-picker>
                    </th>
                    <th v-if="columns.typePayment.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.typePayment"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'Рейс'"
                        ></el-input>
                    </th>
                    <th v-if="columns.typePayment.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.typePayment"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'Страна отправки'"
                        ></el-input>
                    </th>
                  

                    <th v-if="columns.time.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.time"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'Страна прибытия'"
                        ></el-input>
                    </th>

                    <th v-if="columns.sum.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.sum"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'Филиал'"
                        ></el-input>
                    </th>

                    <th v-if="columns.created_at.show">
                        <el-date-picker
                        :placeholder="columns.created_at.title"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        v-model="filterForm.created_at"
                        size="mini"
                        >
                        </el-date-picker>
                    </th>

                    <th v-if="columns.typePayment.show">
                        <el-input
                        clearable
                        size="mini"
                        v-model="filterForm.typePayment"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="'Ответственное лицо'"
                        ></el-input>
                    </th>

                <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="payments in inventory" :key="payments.id" class="cursor-pointer">
                    <td></td>
                    <td>
                      {{ payments.id }}
                    </td>
                    <td>
                      {{ payments.name }}
                    </td>
                    <td>
                      <span @click="showItems(payments.product)" style="cursor: pointer; color: #3490dc;">
                        Просмотр <span class="el-icon-arrow-right"></span>
                    </span>
                    </td>
                    <td>
                      {{ payments.end_date }}
                    </td>
                    <td>
                      <span>{{ payments.status }}</span>
                    </td>
                    <td>
                      {{ payments.priority }}
                    </td>
                    <td>
                      {{ payments.send_country }}
                    </td>
                    <td>
                      {{ payments.arrived_country }}
                    </td>
                    <td>
                      {{ payments.created_at }}
                    </td>
                    <td>
                      {{ payments.filial }}
                    </td>

                    <td v-if="columns.settings.show" class="settings-td">
                        <div class="space__between">
                            <el-dropdown :hide-on-click="false" class="table-bottons-right">
                            <span class="el-dropdown-link more_icons">
                                <i class="el-icon-arrow-down el-icon-more-outline"></i>
                            </span>
                            <el-dropdown-menu
                                slot="dropdown"
                                class="dropdown-menumy-style"
                            >
                                <el-dropdown-item icon="el-icon-edit">
                                Изменить
                                </el-dropdown-item>
                                <el-dropdown-item icon="el-icon-delete">
                                Удалить
                                </el-dropdown-item>
                            </el-dropdown-menu>
                            </el-dropdown>
                            <span @click="watchModal = true" class="el-icon-view blue__text"></span>
                        </div>
                    </td>
                </tr>
            </transition-group>
            </table>
            <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogItemsVisible" width="60%" @closed="empty()" >
                <el-table :data="test">
                    <el-table-column prop="name" :label="$t('message.nomination')">
                        
                    </el-table-column>
                    <el-table-column prop="quantity" :label="$t('message.quantity')">
                        
                    </el-table-column>
                    <el-table-column prop="weight" :label="$t('message.measurement')">
                        
                    </el-table-column>
                    <el-table-column prop="sizes" :label="$t('message.dimension')">
                        
                    </el-table-column>
                    <el-table-column prop="price" :label="$t('message.price')">
                        
                    </el-table-column>
                </el-table>
            </el-dialog>
            <div class="my___pagination">
            <crm-pagination
                :class="mode ? 'pagination__day' : 'pagination__night'"
                @c-change="updatePagination"
                :pagination="pagination"
            ></crm-pagination>
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg bg-se">
            <el-drawer
               :with-header="false"  :visible.sync="watchModal" size="90%" >
                <div>
                    <crm-watch> </crm-watch>
                </div>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmWatch from "./components/crm-watch";
import {mapGetters} from 'vuex'

export default {
    name: "costTransactions",
    components: {
        Pagination,
        CrmWatch,
    },

    data() {
        return {
            watchModal: false,
            dialogItemsVisible: false,
            currentPage2: 5,
            options: [{
                value: 'Турция - Узбекистан',
                label: 'Турция - Узбекистан'
                }, {
                value: 'США - Узбекистан',
                label: 'США - Узбекистан'
                },],
            value: '',
            inventory: [
                {
                    id: 1,
                    number: 11,
                    date: '21.01.2021',
                    name: 'Кан Евгений',
                    product: [
                        {
                            name: 'Iphone 11',
                            quantity: 1,
                            id: 1,
                            weight: '300',
                            sizes: '-',
                            price: '500$'
                        },
                        {
                            name: 'Iphone 13',
                            quantity: 2,
                            id: 1,
                            weight: '340',
                            sizes: '-',
                            price: '850$'
                        }
                    ],
                    start_date: '22.03.2022',
                    end_date: '22.04.2022',
                    status: 'HY9614',
                    flight_date: '22.02.2022',
                    flight_time: '17:30',
                    priority: "Высокий",
                    send_country: "США",
                    arrived_country: "Узбекистан",
                    filial: 'Manomy',
                    created_at: '21.02.2022',
                    updated_at: '22.02.2022',
                    done: 30,
                },
                {
                    id: 2,
                    number: 11,
                    date: '21.01.2021',
                    name: 'Миша',
                    product: [
                        {
                            name: 'Samsung S20 Ultra',
                            quantity: 1,
                            id: 1,
                            weight: '450',
                            sizes: '-',
                            price: '800$'
                        },
                        {
                            name: 'Keychron K2',
                            quantity: 2,
                            id: 1,
                            weight: '900',
                            sizes: '-',
                            price: '80$'
                        }
                    ],
                    start_date: '22.03.2022',
                    end_date: '22.04.2022',
                    status: 'SU1872',
                    flight_date: '22.02.2022',
                    flight_time: '13:30',
                    priority: "Высокий",
                    send_country: "США",
                    arrived_country: "Узбекистан",
                    filial: 'Manomy',
                    created_at: '21.02.2022',
                    updated_at: '22.02.2022',
                    done: 80,
                }
            ],
            checkList: [],
            activeType: "warehouse",
            form: {},
            test: [],
            sarcheBlocks: "",
            filterForm: {
                id: "",
                customer: "",
                paymentType:"",
                typePayment: "",
              
                time: "",
                sum: "",
                currency: "",
                created_at: "",
                updated_at: "",
            },
            list: [
                {
                    id: "1",
                    customer: "Продажа",
                    paymentType:"Входящий платеж",
                    typePayment: "Продажа",
                   
                    time: "2021-05-17 10:32",
                    sum: "250 000 000",
                    currency: "сум",
                    created_at: "02.05.1995",
                    updated_at: "12.12.2020",
                },
                
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                customer: {
                    show: true,
                    title: "Типы расхода",
                    sortable: true,
                    column: "customer",
                },

                paymentType: {
                    show: true,
                    title: "Тип платежа",
                    sortable: true,
                    column: "paymentType",
                },

                typePayment: {
                    show: true,
                    title: "Тип оплаты",
                    sortable: true,
                    column: "typePayment",
                },

               

                time: {
                    show: true,
                    title: "Время",
                    sortable: true,
                    column: "time",
                },
                
                sum: {
                    show: true,
                    title: "Сумма",
                    sortable: true,
                    column: "sum",
                },


                currency: {
                    show: true,
                    title: "Валюта ",
                    sortable: true,
                    column: "currency",
                },

                created_at: {
                    show: true,
                    title: "Дата создания",
                    sortable: true,
                    column: "created_at",
                },

                updated_at: {
                    show: true,
                    title: "Дата изменена ",
                    sortable: true,
                    column: "updated_at",
                },

                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
    methods: {
        handleClick(type) {
        this.activeType = type;
            if (this.genderstatistic) {
                this.changeStatistics();
            }
        },
        showItems(model) {
            this.test = model
            this.dialogItemsVisible = true;
        },
    },
    computed: {
        ...mapGetters({
            mode: "MODE"
        }),
    }
};
</script>
<style scoped>
    .space__between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .space__between span {
        cursor: pointer;
        font-size: 18px;
    }
</style>
